import React, { useState } from "react";
// import reelimg from "../../assests/reelimg/reel.png";
import playbtn from "../../assests/reelimg/playbutton.png";
import curve from "../../assests/orangeimg/curveorng.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import reel1 from "./reel1.jpg";
import reel2 from "./reel2.jpg";
import reel3 from "./reel3.jpg";
import reel4 from "./reel4.jpg";
import reel5 from "./reel5.jpg";
import reelbtn from "./reelbtn.png";
import reelvideo from "../Media/onePercentScheme.mp4";

const Reels = () => {
  const reels = [
    {
      id: "1",
      reelUrl: reelvideo,
    },
  ];

  const options = {
    autoplay: false,
    autoplayTimeout: 2000, // 3 seconds
    loop: true,
    margin: 20,

    responsive: {
      0: {
        items: 1.7,
      },
      600: {
        items: 1.7,
      },
      1000: {
        items: 4.5,
      },
    },
  };

  return (
    <>
      <div className="container-fluid bg-black" id="about">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center justify-content-center">
            <div className="img-container-why">
              <h5 className="about-stoketext">
                {" "}
                INVEST WITH O<span className="per"></span>NE PERCENT
              </h5>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex reel-cont">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={20}
                autoplay
                responsive={options.responsive}
              >
                <div className="reel-cards">
                  <img
                    src={reelbtn}
                    alt=""
                    className="reel-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  />
                  <img className="reel-img" src={reel1} alt="" />
                </div>

                <div className="reel-cards">
                  <img
                    src={reelbtn}
                    alt=""
                    className="reel-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  />
                  <img className="reel-img" src={reel2} alt="" />
                </div>
                <div className="reel-cards">
                  <img
                    src={reelbtn}
                    alt=""
                    className="reel-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  />
                  <img className="reel-img" src={reel5} alt="" />
                </div>

                <div className="reel-cards">
                  <img
                    src={reelbtn}
                    alt=""
                    className="reel-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  />
                  <img className="reel-img" src={reel3} alt="" />
                </div>
                <div className="reel-cards">
                  <img
                    src={reelbtn}
                    alt=""
                    className="reel-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  />

                  <img className="reel-img" src={reel4} alt="" />
                </div>
              </OwlCarousel>
              <div className="gradient"></div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="staticBackdrop"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg d-flex justify-content-center align-items-center">
          <div class="modal-content w-50" >
          <div class="modal-body1">
              <video autoPlay muted controls className="reel-video">
                <source src={reelvideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reels;
