import React, { useState, useEffect } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import logo1 from "./logo.svg";
import logonew from "./1percent.svg";
import img1 from "./111.png";
import LastCard from "./components/Footer/LastCard";
import Footer from "./components/Footer/Footer";
import Reels from "./components/Media/Reels";
import OrangeSection from "./components/orangesection/OrangeSection";
import AboutSection from "./components/About Us/AboutSection";
import curve from "./assests/orangeimg/curveorng.png";
import imh2 from "./1per.png";
import OtpInput from "react-otp-input";
import arrow from "../src/Group 9.png";
import invest from "../src/investment.png";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { _setDefaults } from "gsap/gsap-core";

export default function App() {
  const [otp, setOtp] = useState("");
  const [otpbtn, setOtpBtn] = useState("active");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [val, setVal] = useState("");
  const [response, setResponse] = useState("");
  const [value, setValue] = useState(700);
  const [activeRange, setActiveRange] = useState(525);
  const [defaults, setDefaults] = useState(false);
  const [defaultscolor, setDefaultscolor] = useState(false);

  const setOtpbtn = (e) => {
    e.preventDefault();
    setOtpBtn("false");

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://naad-dev.katsamsoft.com/api/method/naad.api.send_otp?customer_name=${fullName}&mobile_no=${phoneNumber}&sq_feet=600`,
          {
            method: "GET",
            headers: {
              Cookie:
                "full_name=Guest; sid=Guest; system_user=yes; user_id=Guest; user_image=",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log(data); // Handle the response data here

        setDefaults(true);
        setDefaultscolor(true);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchData();
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    setOtpBtn("false");

    const verifyData = async () => {
      try {
        const response = await fetch(
          `https://naad-dev.katsamsoft.com/api/method/naad.api.verify_otp?mobile=${phoneNumber}&size=512&otp=${otp}`,
          {
            method: "GET",
            headers: {
              Cookie:
                "full_name=Guest; sid=Guest; system_user=yes; user_id=Guest; user_image=",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log(data); // Handle the response data here
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    verifyData();
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const setRange = (range) => {
    setActiveRange(range);
  };

  const options = {
    autoplay: false,
    autoplayTimeout: 3000, // 3 seconds
    loop: true,
    margin: 0,

    responsive: {
      0: {
        items: 2,
        margin: 30,
      },
      600: {
        items: 2,
        margin: 20,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <>
      <main className="hero-section">
        <nav class="navbar navbar-expand-lg navbar-light">
          <span className="d-md-block d-sm-none d-none">
            <img src={logo1} className="img-fluid logo1" />
          </span>
          <span className="d-sm-block d-md-none d-flex flex-row">
            <img src={logo1} className="img-fluid logo1" />
            <div className="col-2 border-start oneee">One Percent</div>
          </span>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="collapse navbar-collapse justify-content-center"
            id="navbarTogglerDemo02"
          >
            <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
              <li class="nav-item active">
                <div className="container-ani">
                  <a class="nav-link" href="#why">
                    Why Us
                    {/* <div className="nav-animation"></div> */}
                    <div class="line"></div>
                    <div class="box"></div>
                  </a>
                </div>
              </li>
              <li class="nav-item">
                <div className="container-ani">
                  <a class="nav-link" href="#about">
                    About Us
                    <div class="line"></div>
                    <div class="box"></div>
                  </a>
                </div>
              </li>
              <li class="nav-item">
                <div className="container-ani">
                  <a class="nav-link" href="#amenities">
                    Amenities
                    <div class="line"></div>
                    <div class="box"></div>
                  </a>
                </div>
              </li>
              <li class="nav-item">
                <div className="container-ani">
                  <a class="nav-link" href="#madhur">
                  Madhur Dreams
                    <div class="line"></div>
                    <div class="box"></div>
                   
                  </a>
                </div>
              </li>
              <li class="nav-item">
                <div className="container-ani">
                  <a class="nav-link" href="#last">
                    Investors
                    <div class="line"></div>
                    <div class="box"></div>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>

        <div className="row">
          <div className="banner-caption">
            <h6 className="highlight">
              <u>NEW SCHEME</u>
            </h6>
            <h5>
              BUY your <br></br> dream<br></br> property by <br></br>Investing
              JUST <br></br>
              <span className="orange">1% PER MONTH</span>
            </h5>
          </div>
        </div>
        <div className="arrow-div">
          <div className="nav-arrow">
            <img src={arrow} alt="" />
          </div>
          <div class="center">
            <div class="circle pulse orange-col"></div>
          </div>
        </div>
      </main>
      <div className="container-fluid orange-bg" id="orange-sec">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center justify-content-center">
            <div className="img-container-why" id="why">
              <h5 className="about-stoketext">
                {" "}
                UNLOCK O
                <span className="">
                  <span className="per "></span>NE PERCENT
                </span>
              </h5>
            </div>
            <div className="outersect">
              <div className="container">
                <div className="row">
                  <span className="circleorange"></span>
                  <div className="col-md-6 col-sm-12">
                    <div className="videocard">
                      <img src={img1} className="img-fluid" />
                    </div>
                    <div className="caption">
                      <p className="videotext">
                        introducing a radically real estate service
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="videocard">
                      <img src={img1} className="img-fluid" />
                    </div>
                    <span className="circleorange1"></span>

                    <div className="caption">
                      <p className="videotext">
                        introducing a radically real estate service
                      </p>
                    </div>
                  </div>
                  {/* <div className="bgback"></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-black">
        <div className="container-fluid">
          <div className="row"></div>
        </div>

        <div className="container">
          <div className="row">
          <OwlCarousel className="owl-theme" {...options}>
              <div class="circle-container1">
                <div class="circle1"></div>
                <div class="content">
                  <h6>
                    <span >LOWEST</span> <br></br>
                   <span className="orangetext">EMI RATES</span> 
                  </h6 >
                </div>
              </div>
              <div class="circle-container1">
                <div class="circle1"></div>
                <div class="content">

                <h6>
                    <span >EASY</span> <br></br>
                   <span className="orangetext"> APPROVAL</span> 
                  </h6 >
                  
                </div>
              </div>
              <div class="circle-container1">
                <div class="circle1"></div>
                <div class="content">
                <h6>
                    <span >JUST 1%</span> <br></br>
                   <span className="orangetext">    PER MONTH</span> 
                  </h6 >
                  
               
                </div>
              </div>
              <div class="circle-container1">
                <div class="circle1"></div>
                <div class="content">
                <h6>
                    <span >BEST </span> <br></br>
                   <span className="orangetext">  AMENITIES</span> 
                  </h6 >
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>

        <div className=""></div>

        <div class="sticky-box pulse">
          <div class="slider-container">
            <h5 className="text-center">Download Your Payment Plan</h5>
            {/* <div className="range-slider-container">
              <div className="slider-labels">
                <span className="labeltext">500</span>
                <span className="labeltext">600</span>
                <span className="labeltext">700</span>
                <span className="labeltext">800</span>
                <span className="labeltext">900</span>
                <span className="labeltext">1000</span>
              </div>

              <input
                type="range"
                min="500"
                max="1000"
                step="100"
                value={value}
                onChange={handleChange}
                className="range-slider"
              />

              <div
                className="slider-active-label"
                style={{ left: `${(value - 500) / 5}%` }}
              >
                <span>{value}</span>
                <div className="arrow-down"></div>
              </div>
            </div> */}
            <div className="divider"></div>

            <div className="d-md-block d-sm-none d-none">
              <div className="btn-container">
                <button
                  className={activeRange === 525 ? "orangebtn" : ""}
                  onClick={() => setRange(525)}
                >
                  525 Sq
                </button>
                <button
                  className={activeRange === 600 ? "orangebtn" : ""}
                  onClick={() => setRange(600)}
                >
                  600 Sq
                </button>
                <button
                  className={activeRange === 850 ? "orangebtn" : ""}
                  onClick={() => setRange(850)}
                >
                  850 Sq
                </button>
                <button
                  className={activeRange === 1100 ? "orangebtn" : ""}
                  onClick={() => setRange(1100)}
                >
                  1100 Sq
                </button>
                <button
                  className={activeRange === 1500 ? "orangebtn" : ""}
                  onClick={() => setRange(1500)}
                >
                  1500 Sq
                </button>
              </div>
            </div>

            <div className="d-sm-block d-md-none">
            <div className="btn-container">
              <button
                className={activeRange === 525 ? "orangebtn" : ""}
                onClick={() => setRange(525)}
              >
                525 Sq
              </button>
              <button
                className={activeRange === 600 ? "orangebtn" : ""}
                onClick={() => setRange(600)}
              >
                600 Sq
              </button>
              <button
                className={activeRange === 850 ? "orangebtn" : ""}
                onClick={() => setRange(850)}
              >
                850 Sq
              </button>
              </div>
              <div className="btn-container">
              <button
                className={activeRange === 1100 ? "orangebtn" : ""}
                onClick={() => setRange(1100)}
              >
                1100 Sq
              </button>
              <button
                className={activeRange === 1500 ? "orangebtn" : ""}
                onClick={() => setRange(1500)}
              >
                1500 Sq
              </button>
            </div>
            </div>
           


            


            <div className="d-flex justify-content-center fancy-button medium alizarin down">
              <button className="buttonsvg ">
                <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 18 33"
                  fill="none"
                >
                  <path
                    d="M8.15147 31.8723C8.6201 32.341 9.3799 32.341 9.84853 31.8723L17.4853 24.2356C17.9539 23.7669 17.9539 23.0071 17.4853 22.5385C17.0167 22.0699 16.2569 22.0699 15.7882 22.5385L9 29.3267L2.21177 22.5385C1.74315 22.0699 0.983348 22.0699 0.514718 22.5385C0.0460892 23.0071 0.0460892 23.7669 0.514718 24.2356L8.15147 31.8723ZM7.8 10L7.8 31.0238H10.2L10.2 10H7.8Z"
                    fill="black"
                  />
                </svg>
              </button>

              <button
                className="buttonnew1"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Download Plan
              </button>
            </div>
          </div>
        </div>
      </section>{" "}
      <section id="about">
        <Reels />
      </section>
      <section id="madhur">
        <AboutSection />
      </section>
      <section id="amenities">
        <OrangeSection />
      </section>
      <section id="last">
        <LastCard />
      </section>
      <Footer />
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl d-flex justify-content-center align-items-center">
          <div class="modal-content">
            <div class="modal-body">
              <div className="modalpopup">
                <div className="row">
                  <div className="col-md-6 col-sm-12 p-0 align-self-center">
                    <div className="pad">
                      <h6 className="h6">
                        {" "}
                        <span className="modal-footer-line">DOWN</span>LOAD YOUR
                        PAYMENT PLAN
                      </h6>
                      <div className="modal-forminput">
                        <div className="modal-newletter">
                          <form action="" className="modal-form">
                            <input
                              className="inp"
                              type="text"
                              maxlength="50"
                              required
                              placeholder="Name..."
                              onChange={(e) => setFullName(e.target.value)}
                              disabled={defaults ? true : false}
                            />

                            <input
                              className="inp"
                              type="tel"
                              maxlength="50"
                              required
                              placeholder="Phone..."
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              disabled={defaults ? true : false}
                            />
                            <button
                              onClick={setOtpbtn}
                              disabled={defaults ? true : false}
                              className={
                                defaultscolor === true
                                  ? "defaultscolor"
                                  : "repair"
                              }
                            >
                              Request OTP
                            </button>

                            <div className="modal-enter">
                              {defaultscolor === false ? (
                                <>
                                  <p>
                                    <b className="b">Enter OTP</b>{" "}
                                    <span className="sent text-dark fw-light fs-8">
                                      sent on your number*
                                    </span>{" "}
                                    <span className="within">
                                      (within 60 sec)
                                    </span>
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p>
                                    <b className="b text-black">Enter OTP</b>{" "}
                                    <span className="sent text-dark fw-light fs-8">
                                      sent on your number*
                                    </span>{" "}
                                    <span className="within">
                                      (within 60 sec)
                                    </span>
                                  </p>
                                </>
                              )}
                            </div>
                            <div className= {defaultscolor === false ? "otpinput"
                            : "otpinput1"
                        }>
                              <OtpInput
                                value={otp}
                                onChange={setOtp}
                                
                                numInputs={4}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} />}
                              />
                            </div>
                            <button
                              onClick={verifyOtp}
                              className={
                                defaultscolor === false
                                  ? "defaultscolor down"
                                  : "repair down"
                              }
                              disabled={defaults ? false : true}
                            >
                              Download Plan
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 d-none d-md-block d-sm-none">
                    <img src={invest} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

{
  /* <h6>
                  <u>You are just one Step away from being 1% Investor</u>
                </h6>
                <div className="col-12">
                  <form>
                    <div className="form-label">
                      <label className="label1">Your Name*</label>
                      <input
                        type="text"
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </div>

                    {otpbtn == "active" ? (
                      <>
                        <div className="form-label">
                          <label className="label2">Your Number</label>
                          <input
                            type="tel"
                            minLength={10}
                            maxLength={10}
                            required
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>
                        <div className="fieldRequired">
                          {phoneNumber.length == 10 && fullName.length > 2 ? (
                            <> {val}</>
                          ) : (
                            <>
                              <p>All Field are required</p>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex">
                          <label className="label3 pe-6">
                            xxx-xxx-xx{lastTwo} OTP
                          </label>
                          <span className="resend">
                            <u>Send OTP?</u>
                          </span>
                        </div>
                        <div className="otpinput">
                          <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                      </>
                    )}

                    <div></div>
                  </form>
                </div>

                <div className="d-flex justify-content-center ">
                  {phoneNumber.length == 10 && fullName.length > 2 ? (
                    <>
                      <span onClick={setOtpbtn} className="buttonnew">
                        Send OTP
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="buttonnew border-end border-dark">
                        Download Plan
                      </span>
                    </>
                  )}

                  <button className="buttonsvg ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 10 18 28"
                      fill="none"
                    >
                      <path
                        d="M8.15147 31.8723C8.6201 32.341 9.3799 32.341 9.84853 31.8723L17.4853 24.2356C17.9539 23.7669 17.9539 23.0071 17.4853 22.5385C17.0167 22.0699 16.2569 22.0699 15.7882 22.5385L9 29.3267L2.21177 22.5385C1.74315 22.0699 0.983348 22.0699 0.514718 22.5385C0.0460892 23.0071 0.0460892 23.7669 0.514718 24.2356L8.15147 31.8723ZM7.8 10L7.8 31.0238H10.2L10.2 10H7.8Z"
                        fill="black"
                      />
                    </svg>
                  </button>
                </div> */
}
